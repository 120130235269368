import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import moment from 'moment';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTwitterSquare,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import LinkComponent from '../../utils/linkComponent';

const CopyrightStyled = styled.div`
	background-color: var(--neutral_1);
	color: var(--secondary_6);
	padding: 1em 3em;
	justify-content: center;
	font-size: 0.8em;
	text-align: center;
	/* margin-bottom: 2.7em; */

	a {
		color: var(--secondary_6) !important;
		margin-left: 0.3em;
		margin-right: 0.3em;
	}

	.social_media_icons {
		font-size: 3rem;
	}

	@media (max-width: 768px) {
		/* margin-bottom: 7em; */
	}
`;

const Footer = () => {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			siteBuildMetadata {
				id
				buildTime
			}
		}
	`);
	return (
		<CopyrightStyled>
			<Container></Container>© Copyright 2012-{new Date().getFullYear()} TTL All
			Rights Reserved{' '}
			<LinkComponent to='/privacy-policy'>Privacy Policy -</LinkComponent>
			<LinkComponent to='/terms-and-conditions'>
				Terms and Conditions -
			</LinkComponent>
			{/* <Link to='/careers'>Careers - </Link> */}
			v1.0.0 on{' '}
			{moment(data.siteBuildMetadata.buildTime).format(
				'DD MMM YYYY - HH:mm:ss'
			)}{' '}
			- Site powered by
			<a href='http://www.tektoniclabs.io' target='_blank' rel='noreferrer'>
				Tektonic Labs
			</a>
		</CopyrightStyled>
	);
};
export default Footer;
